import styles from '@atoms/GridMask/GridMask.module.scss';
import Box from '@mui/material/Box';
import { ColorVariant } from '@model/types';
import { useMemo } from 'react';

interface GridMaskBoxProps {
  colorVariant: ColorVariant;
  index: number;
  direction: 'top-middle-bottom' | 'bottom-middle' | 'middle' | 'top-middle';
}

const GridMaskBox = (props: GridMaskBoxProps) => {
  const { colorVariant, index, direction } = props;

  const height = useMemo(() => {
    if (direction === 'bottom-middle') {
      return `calc(100% + ${(index - 2 >= 0 ? -40 : 40) * (index - 2)}px)`;
    }

    if (direction === 'middle') {
      return '100%';
    }

    if (direction === 'top-middle-bottom') {
      return `calc(100% + ${(index - 2 >= 0 ? -80 : 80) * (index - 2)}px)`;
    }

    return `calc(100% + ${(index - 2 >= 0 ? -40 : 40) * (index - 2)}px)`;
  }, [direction, index]);

  return (
    <Box
      width="100%"
      height={height}
      className={`${styles.ColumnDivider} ${colorVariant === 'dark' ? styles.ColumnDividerDark : ''}`}
    />
  );
};

export default GridMaskBox;
