import Row from '@atoms/CustomGrid/Row';
import Grid from '@mui/material/Grid';
import GridContainer from '@atoms/GridContainer/GridContainer';
import { GridMaskProps } from '@atoms/GridMask/GridMask.props';
import { useMemo } from 'react';
import GridMaskBox from '@atoms/GridMask/GridMaskBox';
import styles from './GridMask.module.scss';

const alignItemsDirectionMap = {
  'top-middle': 'flex-end',
  'bottom-middle': 'flex-start',
  'top-middle-bottom': 'center',
  middle: 'stretch',
  none: '',
};

const GridMask = (props: GridMaskProps) => {
  const { colorVariant, direction = 'none' } = props;

  const alignItems = useMemo(() => {
    return alignItemsDirectionMap[direction];
  }, [direction]);

  if (direction === 'none') return null;

  return (
    <GridContainer
      sx={{
        position: 'absolute',
        overflow: 'hidden',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Row height="100%" flexWrap="nowrap">
        {Array.from({ length: 5 }, (_, index) => (
          <Grid
            key={index}
            className={styles.GridItem}
            display="flex"
            flexShrink={0}
            alignItems={alignItems}
            item
            xs={3}
            lg={3}
          >
            <GridMaskBox colorVariant={colorVariant} index={index} direction={direction} />
          </Grid>
        ))}
      </Row>
    </GridContainer>
  );
};

export default GridMask;
